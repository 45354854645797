// const API_URL = process.env.REACT_APP_API_URL ?? "https://localhost:44392/api/";
// const APP_URL = process.env.REACT_APP_APP_URL ?? "http://localhost:8100/";

const API_URL =
  process.env.REACT_APP_API_URL ?? "https://api.dentallabguru.com/api/";
const APP_URL = process.env.REACT_APP_APP_URL ?? "http://localhost:8100/";

// const baseUrl = "https://devapi.dentallabguru.com/api/";
// const appUrl = "https://devapp.dentallabguru.com/";

// const API_URL = "https://localhost:44392/api/";
// const appUrl = "http://localhost:8100/";

const PayPalId =
  process.env.REACT_APP_PAYPAL_ID ??
  "AW0NwIKnMVS1qn4MzBa0fRvp51vucCNPHIZ5wC6MWsBVR0OBxRUYLsFWcNMIVVeMYo5VyXqRP1uGI7nF";

export { API_URL, APP_URL, PayPalId };
